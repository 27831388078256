.paper {
    box-shadow: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid;
    padding: 10px
}

.corrResp {
    color: grey;
    font-size: 14px;
    font-family: sans-serif ;
}