.test-root {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quiz-table-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
}

.test-root table,
td,
th {
  border-collapse: collapse;
  border: 1px solid black;
  padding: 0.25rem 0.5rem;
}

.test-root th {
  min-width: 150px;
  text-align: left;
}
