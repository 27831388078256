.header-root {
  padding: 1em;
  background-color: #fff;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -2px 14px #00000029;
  z-index: 1000;
}

#logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#main-logo {
  margin-right: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-right: #000 solid 1px;
}

#service-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  padding: 0.5rem;
  background-color: rgba(17, 52, 131, 0.05);
  color: var(--primary);
  font: normal normal normal 16px/22px Open Sans;
  border-radius: 5px;
}

#link {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit;
}

